//
// Header
//

.header {
    position: relative;
}

.marquee-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
