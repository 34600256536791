@media (max-width: 480px) {
    .btn-links {
      font-size: 15px;
      padding-top: 25px;
      width: 170px;
      height: 170px;
      resize: none;
      display: flex;
      position: fixed;
      bottom: 0.2px;
      left: 50%;
      transform: translate(-50%, 0px);
      z-index: 999;
      flex: 1;
      /* background-color: red; */
      /* border: 2px solid red; */
      border-radius: 150px;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    .btn-links {
      font-size: 15px;
      padding-top: 20px;
      width: 170px;
      height: 170px;
      resize: none;
  }

  .dvLoading {
    background: url(../../../../assets/img/theme/loader.gif) no-repeat center center #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999999;
  }
  }