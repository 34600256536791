.carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff !important;
  background: #460e7b;
  text-align: center;
}

.custom-tag {
  max-width: 100%;
  height: 700px;
}

.carousel-caption > h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 51px;
  color: white;
}

.login-container {
  padding-top: 230px;
}

.modal-content {
  top: 200px;
}

/* admin page */
.upload-image-container {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfd3d3;
  border-radius: 5px;
}

.upload-image-container:hover {
  border: 1px dashed #dfd3d3;
}

.upload-image-canvas {
  width: 50%;
  background-color: #f5f5f5;
  height: 200px;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.upload-image-canvas:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.hide-input {
  display: none;
}

.upload-image-preview {
  width: 100%;
  border-radius: 5px;
  border: none;
}

.preview-image-items {
  width: 120px;
}

.filter-page {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-page span {
  font-size: 12px;
}

.section-1 {
  flex: 1;
}

.section-2 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.filter-select {
  height: 35px;
  font-size: 12px;
  padding: 0px 10px 0px 10px;
  border-radius: 4px;
  background-color: transparent;
  margin: 0px 10px 0px 10px;
  border: 1px solid #dfd3d3;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.err-text {
  font-size: 12px;
  color: red;
  font-weight: 700;
  margin-top: 15px;
  display: block;
}

.detail-popup {
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #dfd3d3;
  padding-top: 20px;
}

.table-key {
  background-color: #ececec;
  color: #363636;
  font-weight: 700;
  border: 1px solid #dfd3d3;
}
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-container .form-group {
  margin-right: 20px;
}
@media screen and (max-width: 480px) {
  .upload-image-canvas {
    width: 90%;
  }
  .filter-page {
    flex-direction: column;
    border-top: 1px solid #dfd3d3;
  }
  .section-1 {
    padding-top: 20px;
  }
  .section-2 {
    margin-top: 20px;
    justify-content: center;
  }
  .flex-container {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .flex-container .form-group {
    width: 100%;
    margin-right: 0px;
  }
  .flex-container button {
    width: 100%;
  }
}

.react-bootstrap-table-pagination {
  margin-top: 15px;
}

.no-autocomplete {
  color: #999;
  padding: 10px;
}

.autocomplete {
  border: 0.5px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.autocomplete li {
  padding: 5px;
}

.autocomplete.active,

.autocomplete li:hover {
  background-color: rgb(255, 153, 0);
  cursor: pointer;
  font-weight: 700;
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

:disabled {
	cursor: not-allowed;
}
.pointer {
	cursor: pointer;
}

/* Custom classname */
.boxShadow {
	box-shadow: 1px 1px 24px 2px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 1px 1px 24px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 1px 24px 2px rgba(0, 0, 0, 0.2);
}

/* itemMain: Index.js */
.bgSearch:hover {
	background-color: orange;
	cursor: pointer;
}

.photo {
  height: 200px;
  width: 200px;
}

.qrcode {
  height: 50px;
  width: 50px;
}