.dropzone {
  .dz-button {
    padding: 0;
    border: none;
    text-align: center;
    color: $gray-600;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
  }
}
