//
// Image
//

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.responsive-img {
    max-width: 100%;
    height: auto;
    display: block;
  }