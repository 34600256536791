.fixed-whatsapp { position: fixed; bottom: 40px; Right: 20px; width: 50px; height: 50px; z-index: 9999; } 
/* .fixed-whatsapp:before { 
    content: ""; 
    background-repeat: no-repeat;
    background-size: 34px 34px; 
    background-position: center center;
    width: 50px; 
    height: 50px; 
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg viewBox='0 0 24 24' width='32' height='32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg%3e%3cpath style='fill:%23ffffff' d='M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z'%3e%3c/path%3e%3c/g%3e%3c/svg%3e"); 
    background-color: #000000; 
    position: absolute; 
    top: 0; 
    left: 0; 
    border-radius: 100%;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, .12), 0 1px 1px 0 rgba(0, 0, 0, .24); }  */
    
.encepnurdin { -webkit-animation:rubberBand 1s linear 1s infinite normal;
    animation:rubberBand 1s linear 1s infinite normal} 

    .text {
        position: relative;
        font-size: 14px;
        color: white;
        width: 30px; /* Could be anything you like. */
      }
      
      .text-concat {
        position: relative;
        display: inline-block;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.6em; /* (Number of lines you want visible) * (line-height) */
        line-height: 1.2em;
        text-align:justify;
      }
      
      .text.ellipsis::after {
        content: "...";
        position: absolute;
        right: -12px; 
        bottom: 4px;
      }
